import { useState } from 'react';
import { countryArr } from './data/country';
import { goods } from './data/goods';
import './App.css';


const tg = "lm099bot";
const payHost = "https://pay.lm99.pro/";


function App() {

  const [country, setCountry] = useState('');
  const [item, setItem] = useState('');
  const [loading, setLoading] = useState(false);




  const getCode = () => {
    if (!country) {
      alert('请选择地区');
      return;
    }

    if (!item) {
      alert('请选择项目');
      return;
    }

    setLoading(true);
    setTimeout(() => {
      window.location.href = `${payHost}?price=${item}&orderNo=${Math.random().toString(36).substr(2)}`;
    }, 1500);
  }

  const copy = () => {
    alert('请先获取号码');
  }


  return (
    <div className="App w-full h-full">
      <div className='bg-gradient-to-tl from-a to-b p-2 '>
        <div className='flex items-center justify-center mt-2'>
          <img src="static/img/logo.png" alt="logo" className='h-6' />
        </div>
        <h1 className='text-[30px] font-bold mt-1 text-center text-white'>在线接码</h1>
        <div className='text-white text-[20px] mt-1' >接码短信平台集合实卡短信接码</div>
      </div>

      <div className='bg-white'>
        <div className='flex px-2 pt-2 items-center'>
          <img className='h-6' src="static/img/notice.png" alt="notice" />
          <div className='font-bold text-sm'>公告</div>
        </div>

        <div className='p-2 text-[15px] text-left text-gray-500'>
          本网站接码号全部实卡接码,接码率可以达到百分之98,不成功不扣费,欢迎大家使用!
          本平台对注册来的小号都是首卡,收到验证码则订单服务完成!
          所有业务均来对接第三方平台！本站无自营业务!
          全球接码短信平台,接码、短信代发一体化接码平台
        </div>
        <div className='flex items-center justify-between p-2 text-sm'>
            <div>在线客服</div>
            <div className='text-[15px] font-bold text-blue-500'><a href={`https://t.me/${tg}`}>{`@${tg}`}</a></div>
          </div>
      </div>
      <div className='bg-white rounded p-2 mt-2'>
        <div className='flex items-center w-full justify-between border-b pb-2 border-gray-200'>
          <div className='flex'>
            <div className='text-sm w-[70px] text-left'>地区</div>
            <div className='text-sm text-red-500'>*</div>
          </div>

          <div className='flex justify-end items-center'>
            <select className='w-full border-transparent border-none rounded text-right text-gray-600 p-1 focus:outline-none text-sm' onChange={(e) => {
              setCountry(e.target.value);
            }}>
              <option value=''>请选择地区</option>
              {

                countryArr.map((item: any) => {
                  return <option key={item.id} value={item.name}>{
                    item.name
                  }</option>
                }
                )
              }
            </select>

          </div>
        </div>
        <div className='flex items-center w-full justify-between mt-2  border-b pb-2 border-gray-200'>
          <div className='flex'>
            <div className='text-sm w-[70px] text-left'>项目</div>
            <div className='text-sm text-red-500'>*</div>
          </div>

          <div className='flex justify-end items-center'>
            <select className='w-full border-transparent border-none rounded text-right text-gray-600 p-1 focus:outline-none text-sm' onChange={(e) => {
              setItem(e.target.value);
              console.log(e.target.value);
            }}>
              <option value=''>请选择项目</option>
              {

                goods.map((item: any) => {
                  return <option key={item.id} value={item.price}>{
                    item.name
                  }</option>
                }
                )
              }
            </select>

          </div>

        </div>
        <div className='flex items-center justify-between mt-2'>
          <div className='text-sm w-[70px] text-left'>手机号码</div>
          <button className='font-medium text-[15px] text-white rounded bg-blue-500 px-2 py-1' onClick={getCode}>获取号码</button>
        </div>
      </div>

      <div className='bg-white rounded p-2 mt-2'>
        <div className='flex items-center w-full justify-between mt-2  border-b pb-2 border-gray-200'>
          <div className='flex flex-1 items-center'>
            <div className='text-sm w-[70px] text-left'>验证码</div>
            <div className='font-medium text-[15px] text-gray-400 roundedpx-2 py-1'>等待获取验证码</div>
          </div>
          <button className='font-medium text-[15px] text-white rounded bg-blue-500 px-2 py-1' onClick={copy}>复制</button>
        </div>
        <div className='flex items-center w-full justify-between mt-2'>
          <div className='flex flex-1 items-center'>
            <div className='text-sm w-[70px] text-left'>短信</div>
            <div className='font-medium text-[15px] text-gray-400 roundedpx-2 py-1'>等待获取短信内容</div>
          </div>
          <button className='font-medium text-[15px] text-white rounded bg-blue-500 px-2 py-1' onClick={copy}>复制</button>
        </div>
      </div>

      {
        loading && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
            <div className="bg-white p-2 rounded-lg shadow-lg">
              <div className='m-2 p-2'>
                <div className='text-blue-400 font-bold'>提交中...</div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default App;
