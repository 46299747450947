export const goods:any =  [
        {
            "id": 11,
            "name": "telegram(飞机)----0.15 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.15
        },
        {
            "id": 12,
            "name": "WhatsApp----0.15 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.15
        },
        {
            "id": 13,
            "name": "hinge----0.2 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.2
        },
        {
            "id": 27,
            "name": "Soul----0.3 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.3
        },
        {
            "id": 32,
            "name": "币安----0.25 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.25
        },
        {
            "id": 34,
            "name": "欧易----0.5 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.5
        },
        {
            "id": 108,
            "name": "facebook----0.18 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.18
        },
        {
            "id": 110,
            "name": "GoogleVoice----0.53 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.53
        },
        {
            "id": 111,
            "name": "Twitter----0.24 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.24
        },
        {
            "id": 112,
            "name": "google----0.18 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.18
        },
        {
            "id": 113,
            "name": "yahoo----0.12 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.12
        },
        {
            "id": 114,
            "name": "paypal----0.15 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.15
        },
        {
            "id": 118,
            "name": "tiktok----0.15 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.15
        },
        {
            "id": 119,
            "name": "亚马逊----0.16 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.16
        },
        {
            "id": 120,
            "name": "火币----0.24 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.24
        },
        {
            "id": 126,
            "name": "ebay----0.12 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.12
        },
        {
            "id": 127,
            "name": "discord----0.18 usdt",
            "sort": 0,
            "status": 1,
            "price": 0.18
        },
        {
            "id": 133,
            "name": "Instagram----0.13usdt",
            "sort": 0,
            "status": 1,
            "price": 0.13
        }
    ]