export const countryArr:any = [
    {
        id: 1,
        name: 'US/美国 +1',
    },
    {
        id: 2,
        name: 'GB/英国 +44',
    },
    {
        id: 3,
        name: 'VN/越南 +84',
    },
    {
        id: 4,
        name: 'SA/沙特阿拉伯 +966',
    },
    {
        id: 5,
        name: 'SG/新加坡 +65',
    },
    {
        id: 6,
        name: 'AR/阿根廷 +54',
    },
    {
        id: 7,
        name: 'TR/土耳其 +90',
    },
    {
        id: 8,
        name: 'AU/澳大利亚 +61',
    },
    {
        id: 9,
        name: 'AT/奥地利 +43',
    },
    {
        id: 10,
        name: 'ZA/南非 +27',
    },
    {
        id: 11,
        name: 'BS/巴哈马 +1242',
    },
    {
        id: 12,
        name: 'TH/泰国 +66',
    },
    {
        id: 13,
        name: 'BD/孟加拉国 +880',
    },
    {
        id: 14,
        name: 'MX/墨西哥 +52',
    },
    {
        id: 15,
        name: 'BY/白俄罗斯 +375',
    },
    {
        id: 16,
        name: 'BE/比利时 +32',
    },
    {
        id: 17,
        name: 'BZ/伯利兹 +501',
    },
    {
        id: 18,
        name: 'BJ/贝宁 +229',
    },
    {
        id: 19,
        name: 'BM/百慕大群岛 +1441',
    },
    {
        id: 20,
        name: 'BO/玻利维亚 +591',
    },
    {
        id: 21,
        name: 'BW/博茨瓦纳 +267',
    },
    {
        id: 22,
        name: 'BR/巴西 +55',
    },
    {
        id: 23,
        name: 'BN/文莱 +673',
    },
    {
        id: 24,
        name: 'BG/保加利亚 +359',
    },
    {
        id: 25,
        name: 'BF/布基纳法索 +226',
    },
    {
        id: 26,
        name: 'MM/缅甸 +95',
    },
    {
        id: 27,
        name: 'BI/布隆迪 +257',
    },
    {
        id: 28,
        name: 'CM/喀麦隆 +237',
    },
    {
        id: 29,
        name: 'CA/加拿大 +1',
    },
    {
        id: 30,
        name: 'CF/中非共和国 +236',
    },
    {
        id: 31,
        name: 'TD/乍得 +235',
    },
    {
        id: 32,
        name: 'CL/智利 +56',
    },
    {
        id: 33,
        name: 'AO/安哥拉 +244',
    },
    {
        id: 34,
        name: 'CO/哥伦比亚 +57',
    },
    {
        id: 35,
        name: 'CG/刚果 +242',
    },
    {
        id: 36,
        name: 'CK/库克群岛 +682',
    },
    {
        id: 37,
        name: 'CR/哥斯达黎加 +506',
    },
    {
        id: 38,
        name: 'CU/古巴 +53',
    },
    {
        id: 39,
        name: 'CY/塞浦路斯 +357',
    },
    {
        id: 40,
        name: 'CZ/捷克 +420',
    },
    {
        id: 41,
        name: 'DK/丹麦 +45',
    },
    {
        id: 42,
        name: 'DJ/吉布提 +253',
    },
    {
        id: 43,
        name: 'DO/多米尼加共和国 +1890',
    },
    {
        id: 44,
        name: 'EC/厄瓜多尔 +593',
    },
    {
        id: 45,
        name: 'EG/埃及 +20',
    },
    {
        id: 46,
        name: 'SV/萨尔瓦多 +503',
    },
    {
        id: 47,
        name: 'EE/爱沙尼亚 +372',
    },
    {
        id: 48,
        name: 'ET/埃塞俄比亚 +251',
    },
    {
        id: 49,
        name: 'FJ/斐济 +679',
    },
    {
        id: 50,
        name: 'FI/芬兰 +358',
    },
    {
        id: 51,
        name: 'FR/法国 +33',
    },
    {
        id: 52,
        name: 'GF/法属圭亚那 +594',
    },
    {
        id: 53,
        name: 'GA/加蓬 +241',
    },
    {
        id: 54,
        name: 'GM/冈比亚 +220',
    },
    {
        id: 55,
        name: 'GE/格鲁吉亚 +995',
    },
    {
        id: 56,
        name: 'DE/德国 +49',
    },
    {
        id: 57,
        name: 'GH/加纳 +233',
    },
    {
        id: 58,
        name: 'GI/直布罗陀 +350',
    },
    {
        id: 59,
        name: 'GR/希腊 +30',
    },
    {
        id: 60,
        name: 'GD/格林纳达 +1809',
    },
    {
        id: 61,
        name: 'GU/关岛 +1671',
    },
    {
        id: 62,
        name: 'GT/危地马拉 +502',
    },
    {
        id: 63,
        name: 'GN/几内亚 +224',
    },
    {
        id: 64,
        name: 'GY/圭亚那 +592',
    },
    {
        id: 65,
        name: 'HT/海地 +509',
    },
    {
        id: 66,
        name: 'HN/洪都拉斯 +504',
    },
    {
        id: 67,
        name: 'HK/香港 +852',
    },
    {
        id: 68,
        name: 'HU/匈牙利 +36',
    },
    {
        id: 69,
        name: 'IS/冰岛 +354',
    },
    {
        id: 70,
        name: 'IN/印度 +91',
    },
    {
        id: 71,
        name: 'ID/印度尼西亚 +62',
    },
    {
        id: 72,
        name: 'IR/伊朗 +98',
    },
    {
        id: 73,
        name: 'IQ/伊拉克 +964',
    },
    {
        id: 74,
        name: 'IE/爱尔兰 +353',
    },
    {
        id: 75,
        name: 'IL/以色列 +972',
    },
    {
        id: 76,
        name: 'IT/意大利 +39',
    },
    {
        id: 77,
        name: 'JM/牙买加 +1876',
    },
    {
        id: 78,
        name: 'JP/日本 +81',
    },
    {
        id: 79,
        name: 'JO/约旦 +962',
    },
    {
        id: 80,
        name: 'KH/柬埔寨 +855',
    },
    {
        id: 81,
        name: 'KZ/哈萨克斯坦 +327',
    },
    {
        id: 82,
        name: 'KE/肯尼亚 +254',
    },
    {
        id: 83,
        name: 'KR/韩国 +82',
    },
    {
        id: 84,
        name: 'KW/科威特 +965',
    },
    {
        id: 85,
        name: 'KG/吉尔吉斯坦 +331',
    },
    {
        id: 86,
        name: 'LA/老挝 +856',
    },
    {
        id: 87,
        name: 'LV/拉脱维亚 +371',
    },
    {
        id: 88,
        name: 'LB/黎巴嫩 +961',
    },
    {
        id: 89,
        name: 'LS/莱索托 +266',
    },
    {
        id: 90,
        name: 'LR/利比里亚 +231',
    },
    {
        id: 91,
        name: 'LY/利比亚 +218',
    },
    {
        id: 92,
        name: 'LI/列支敦士登 +423',
    },
    {
        id: 93,
        name: 'LT/立陶宛 +370',
    },
    {
        id: 94,
        name: 'LU/卢森堡 +352',
    },
    {
        id: 95,
        name: 'MO/澳门 +853',
    },
    {
        id: 96,
        name: 'MG/马达加斯加 +261',
    },
    {
        id: 97,
        name: 'MW/马拉维 +265',
    },
    {
        id: 98,
        name: 'MY/马来西亚 +60',
    },
    {
        id: 99,
        name: 'MV/马尔代夫 +960',
    },
    {
        id: 100,
        name: 'ML/马里 +223',
    },
    {
        id: 101,
        name: 'MT/马耳他 +356',
    },
    {
        id: 102,
        name: 'MU/毛里求斯 +230',
    },
    {
        id: 103,
        name: 'BB/巴巴多斯 +1246',
    },
    {
        id: 104,
        name: 'MD/摩尔多瓦 +373',
    },
    {
        id: 105,
        name: 'MC/摩纳哥 +377',
    },
    {
        id: 106,
        name: 'MN/蒙古 +976',
    },
    {
        id: 107,
        name: 'MS/蒙特塞拉特岛 +1664',
    },
    {
        id: 108,
        name: 'MA/摩洛哥 +212',
    },
    {
        id: 109,
        name: 'MZ/莫桑比克 +258',
    },
    {
        id: 110,
        name: 'NA/纳米比亚 +264',
    },
    {
        id: 111,
        name: 'NR/瑙鲁 +674',
    },
    {
        id: 112,
        name: 'NP/尼泊尔 +977',
    },
    {
        id: 113,
        name: 'NL/荷兰 +31',
    },
    {
        id: 114,
        name: 'NZ/新西兰 +64',
    },
    {
        id: 115,
        name: 'NI/尼加拉瓜 +505',
    },
    {
        id: 116,
        name: 'NE/尼日尔 +227',
    },
    {
        id: 117,
        name: 'NG/尼日利亚 +234',
    },
    {
        id: 118,
        name: 'KP/朝鲜 +850',
    },
    {
        id: 119,
        name: 'NO/挪威 +47',
    },
    {
        id: 120,
        name: 'OM/阿曼 +968',
    },
    {
        id: 121,
        name: 'PK/巴基斯坦 +92',
    },
    {
        id: 122,
        name: 'PA/巴拿马 +507',
    },
    {
        id: 123,
        name: 'PG/巴布亚新几内亚 +675',
    },
    {
        id: 124,
        name: 'PY/巴拉圭 +595',
    },
    {
        id: 125,
        name: 'PE/秘鲁 +51',
    },
    {
        id: 126,
        name: 'PH/菲律宾 +63',
    },
    {
        id: 127,
        name: 'PL/波兰 +48',
    },
    {
        id: 128,
        name: 'PF/法属玻利尼西亚 +689',
    },
    {
        id: 129,
        name: 'PT/葡萄牙 +351',
    },
    {
        id: 130,
        name: 'PR/波多黎各 +1787',
    },
    {
        id: 131,
        name: 'QA/卡塔尔 +974',
    },
    {
        id: 132,
        name: 'RO/罗马尼亚 +40',
    },
    {
        id: 133,
        name: 'RU/俄罗斯 +7',
    },
    {
        id: 134,
        name: 'LC/圣卢西亚 +1758',
    },
    {
        id: 135,
        name: 'VC/圣文森特岛 +1784',
    },
    {
        id: 136,
        name: 'SM/圣马力诺 +378',
    },
    {
        id: 137,
        name: 'ST/圣多美和普林西比 +239',
    },
    {
        id: 138,
        name: 'AI/安圭拉岛 +1264',
    },
    {
        id: 139,
        name: 'SN/塞内加尔 +221',
    },
    {
        id: 140,
        name: 'SC/塞舌尔 +248',
    },
    {
        id: 141,
        name: 'SL/塞拉利昂 +232',
    },
    {
        id: 142,
        name: 'AG/安提瓜和巴布达 +1268',
    },
    {
        id: 143,
        name: 'SK/斯洛伐克 +421',
    },
    {
        id: 144,
        name: 'SI/斯洛文尼亚 +386',
    },
    {
        id: 145,
        name: 'SB/所罗门群岛 +677',
    },
    {
        id: 146,
        name: 'SO/索马里 +252',
    },
    {
        id: 147,
        name: 'AZ/阿塞拜疆 +994',
    },
    {
        id: 148,
        name: 'ES/西班牙 +34',
    },
    {
        id: 149,
        name: 'LK/斯里兰卡 +94',
    },
    {
        id: 150,
        name: 'LC/圣卢西亚 +1758',
    },
    {
        id: 151,
        name: 'VC/圣文森特 +1784',
    },
    {
        id: 152,
        name: 'SD/苏丹 +249',
    },
    {
        id: 153,
        name: 'SR/苏里南 +597',
    },
    {
        id: 154,
        name: 'SZ/斯威士兰 +268',
    },
    {
        id: 155,
        name: 'SE/瑞典 +46',
    },
    {
        id: 156,
        name: 'CH/瑞士 +41',
    },
    {
        id: 157,
        name: 'SY/叙利亚 +963',
    },
    {
        id: 158,
        name: 'TW/台湾省 +886',
    },
    {
        id: 159,
        name: 'TJ/塔吉克斯坦 +992',
    },
    {
        id: 160,
        name: 'TZ/坦桑尼亚 +255',
    },
    {
        id: 161,
        name: 'BH/巴林 +973',
    },
    {
        id: 162,
        name: 'TG/多哥 +228',
    },
    {
        id: 163,
        name: 'TO/汤加 +676',
    },
    {
        id: 164,
        name: 'TT/特立尼达和多巴哥 +1809',
    },
    {
        id: 165,
        name: 'TN/突尼斯 +216',
    },
    {
        id: 166,
        name: 'AM/亚美尼亚 +374',
    },
    {
        id: 167,
        name: 'TM/土库曼斯坦 +993',
    },
    {
        id: 168,
        name: 'UG/乌干达 +256',
    },
    {
        id: 169,
        name: 'UA/乌克兰 +380',
    },
    {
        id: 170,
        name: 'AE/阿拉伯联合酋长国 +971',
    },
    {
        id: 171,
        name: 'AL/阿尔巴尼亚 +355',
    },
    {
        id: 172,
        name: 'DZ/阿尔及利亚 +213',
    },
    {
        id: 173,
        name: 'UY/乌拉圭 +598',
    },
    {
        id: 174,
        name: 'UZ/乌兹别克斯坦 +233',
    },
    {
        id: 175,
        name: 'VE/委内瑞拉 +58',
    },
    {
        id: 176,
        name: 'AD/安道尔共和国 +376',
    },
    {
        id: 177,
        name: 'YE/也门 +967',
    },
    {
        id: 178,
        name: 'YU/南斯拉夫 +381',
    },
    {
        id: 179,
        name: 'ZW/津巴布韦 +263',
    },
    {
        id: 180,
        name: 'ZR/扎伊尔 +243',
    },
    {
        id: 181,
        name: 'ZM/赞比亚 +260',
    },
        {
        id: 182,
        name: 'CH/中国 +86',
    },
    {
        id: 183,
        name: 'HK/香港 +852',
    },
    {
        id: 184,
        name: 'MAC/澳门 +853',
    },
    {
        id: 185,
        name: 'TW/台湾 +886',
    }
];